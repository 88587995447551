<template>
  <div>
    <form
      ref="form"
      class="mt-5 md:mt-8 ml-auto mr-auto"
      @submit.prevent="submit"
    >
      <div class="flex flex-col md:flex-row md:h-full">
        <input
          v-model="email"
          size="5"
          class="w-auto flex-grow py-2 px-4 md:py-4 md:px-6 border appearance-none article-label text-base font-medium placeholder-opacity-50 text-black leading-tight focus:outline-none focus:shadow-outline"
          :class="{ 'rounded-md': isMm, 'rounded-full': !isMm }"
          type="email"
          :placeholder="$t('Email')"
          required
        />
        <CommonButton
          :bg="isMm ? 'bg-black' : 'bg-blue'"
          text="text-white"
          border="border-none"
          size=""
          class="md:ml-2 md:h-auto h-9 mt-4 md:mt-0"
          @click="checkConsent"
        >
          {{ submitLabel }}
        </CommonButton>
      </div>
      <div class="flex flex-row items-start justify-start mt-6 mx-1">
        <label class="flex">
          <FormCheckbox
            ref="checkbox"
            :required="true"
            :added-class="{ 'border-red': makeConsentRed }"
            @input="updateConsent"
          />
          <i18n-t
            keypath="newsletterConsent.text"
            tag="div"
            scope="global"
            class="body-s"
            :class="{ 'text-red': makeConsentRed }"
          >
            <template #siteName>
              <span>{{ config.public.site.name }}</span>
            </template>
            <template #alike>
              <CommonTooltip :text="$t('newsletterConsent.alike.more')">
                <span class="underline">{{
                  $t('newsletterConsent.alike.text')
                }}</span>
              </CommonTooltip>
            </template>
            <template #group>
              <CommonTooltip :text="$t('newsletterConsent.group.more')">
                <span class="underline">{{
                  $t('newsletterConsent.group.text')
                }}</span>
              </CommonTooltip>
            </template>
            <template #privacyPolicy>
              <NuxtLink
                :to="{
                  name: 'article',
                  params: {
                    id: config.public.site.cookiePolicy,
                  },
                }"
                class="underline hover:text-blue"
              >
                <span>{{ $t('newsletterConsent.privacyPolicy.text') }}</span>
              </NuxtLink>
            </template>
          </i18n-t>
        </label>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
const { isMm } = useBrand()
const props = withDefaults(
  defineProps<{
    large?: boolean
    buttonText?: string
    eventLabel?: string
    newsletter?: string | number
  }>(),
  {
    eventLabel: 'FreeNewsletterSignup',
    newsletter: 1, // 1 = the free frontpage newsletter.
  }
)

const emit = defineEmits(['signed-up'])

const email = ref('')
const consentGiven = ref(false)
const makeConsentRed = ref(false)

const config = useRuntimeConfig()
const nuxtApp = useNuxtApp()
const submitLabel = computed(() => props.buttonText || nuxtApp.$t('Signup4'))

const { nwCookie } = useNwCookie()
const gtm = useGtm()

async function submit() {
  await nuxtApp.$api.user
    .subscribeFreeNewsletter(email.value, FREE_NEWSLETTER_ID)
    .then(() => {
      emit('signed-up')

      gtm?.trackEvent({
        event: 'formTracking',
        eventCategory: 'Form',
        eventAction: 'Form Completed',
        eventLabel: props.eventLabel,
      })

      nwCookie.value = true
    })
    .catch((error) => {
      console.error('Failed to free subscribe to newsletter', error)
    })
}

function checkConsent() {
  makeConsentRed.value = !consentGiven.value
}

function updateConsent(newValue: boolean) {
  consentGiven.value = newValue
  if (makeConsentRed.value && newValue) {
    makeConsentRed.value = false
  }
}
</script>
